<script setup>
	// Libraries
	import { storeToRefs } from 'pinia';
	// Composables
	import { makeHash } from '@/composables/helpers/makeHash';

	// Stores
	import { useGlobalStore } from '~/stores/global';

	// Props
	const props = defineProps({
		prices: {
			type: Object,
			required: true,
		},
		performanceId: {
			type: [Number, Boolean],
			required: false,
			default: false,
		},
		productionSeasonId: {
			type: [Number, Boolean],
			required: false,
			default: false,
		},
		isTour: {
			type: Boolean,
			required: false,
			default: true,
		},
		button: {
			type: Object,
			required: false,
			default: false,
		},
	});

	// Reactive Props
	const { prices, productionSeasonId, performanceId, isTour, button } = toRefs(props);

	//  Store Variables
	const { tourDetailPage, ticketPricingMap } = useGlobalStore();

	// Variables
	const { $bus } = useNuxtApp();
	const ticketTypesArray = ref([0]);
	const ticketTypeCount = ref(0);
	const quantities = ref({ 0: { count: 1 } });
	const tickets = ref(null);
	const total = ref(0);
	const ticketTypesUsed = ref([]); // Track used ticket types
	const user = useState('user', () => false);
	const maxTickets = ref(0);
	const submitting = ref(false);
	const errors = ref([]);
	const showError = ref(false);

	const membershipLimits = computed(() => {
		const limits = {};

		const findMax = (membership) => {
			let limit = 1;
			ticketPricingMap.forEach((pricing) => {
				if (pricing.membershipDescriptions) {
					pricing.membershipDescriptions.forEach((details) => {
						const match = membership === details.description;
						if (match) limit = details.limit;
					});
				}
			});
			return limit;
		};

		prices.value.forEach((price) => {
			price.memberships.forEach((membership) => {
				if (!limits[membership]) limits[membership] = findMax(membership);
			});
		});
		return limits;
	});

	// Computed
	const quantity = computed(() => {
		if (quantities.value) {
			return Object.values(quantities.value).reduce((acc, curr) => {
				return acc + curr.count;
			}, 0);
		} else {
			return 0;
		}
	});

	const publicTickets = computed(() => {
		return prices.value.filter((price) => price.memberships && price.memberships.length === 0);
	});

	const membershipTickets = computed(() => {
		const membership = user.value?.membership?.level || false;
		if (!membership) return [];
		return prices.value.filter((price) => price.memberships.includes(membership));
	});

	const ticketOptions = computed(() => {
		const newPrices = [...publicTickets.value, ...membershipTickets.value];

		const prices = [];
		const uniquePrices = [];

		for (const price of newPrices) {
			const hasPrice = prices.includes(price.price);
			if (!hasPrice) {
				prices.push(price.price);
				uniquePrices.push(price);
			} else {
				const index = prices.indexOf(price.price);
				const duplicate = uniquePrices[index];
				if (duplicate.description.toLowerCase().includes('member')) {
					uniquePrices[index].description = uniquePrices[index].description + ' and above';
				}
			}
		}

		const values = uniquePrices.map((option) => {
			let label = '$';
			label += option.price;
			label += ' - ';
			label += option.description;
			return { label, value: option.price, pricetypeId: option.pricetypeId };
		});

		return values;
	});

	// Methods
	const updateMaxTickets = async () => {
		if (!performanceId.value) {
			maxTickets.value = 9;
		} else {
			const max = await $fetch('/api/tn/ticket-count?performanceIds=' + performanceId.value);
			console.log(`🍤 ~ updateMaxTickets ~ max:`, max);
			const count = max[0].count;
			if (count <= 9) {
				maxTickets.value = count;
			} else {
				maxTickets.value = 9;
			}
		}
	};

	const addTicketType = () => {
		if (quantity.value >= maxTickets.value) {
			quantities.value[0].count = quantity.value - 1;
		}

		const newTicketType = ticketTypeCount.value + 1;
		ticketTypesArray.value.push(newTicketType);

		quantities.value[newTicketType] = { count: 1 };

		ticketTypeCount.value = newTicketType;

		setTimeout(() => {
			updateTicketTypesUsed();
		}, 16);
	};

	const removeType = (ticketType) => {
		const index = ticketTypesArray.value.indexOf(ticketType);
		ticketTypesArray.value.splice(index, 1);
		delete quantities.value[ticketType];
		setTimeout(() => {
			updateTicketTypesUsed();
		}, 16);
	};

	const updateQuantity = (event) => {
		const value = event.target.value;
		const index = event.target.getAttribute('data-index');
		quantities.value[index].count = parseInt(value);
	};

	const updateSingleQuantity = (index, operator) => {
		let count = quantities.value[index].count;

		switch (operator) {
			case '-':
				const shouldGoDown = count > 1;
				if (shouldGoDown) {
					count = count - 1;
				}
				break;
			default:
				count = count + 1;
				break;
		}

		const max = getMaxCount(index);
		if (count > max) return;

		quantities.value[index].count = count;
		updateTotal();
		updateTicketTypesUsed();
	};

	const updateTotal = () => {
		if (!tickets.value) return (total.value = 0);
		const newTotal = tickets.value.reduce((acc, curr) => {
			const quantityIndex = curr.getAttribute('data-index');
			const quantity = quantities.value[quantityIndex]?.count || 0;
			const price = parseInt(curr.value) * quantity;
			return acc + price;
		}, 0);
		total.value = newTotal;
	};

	const updateTicketTypesUsed = () => {
		if (!tickets.value) return;
		const newValues = [];
		tickets.value.forEach((ticket) => {
			const label = ticket.options[ticket.selectedIndex].text;
			const id = ticket.getAttribute('data-index');
			const ticketTypeId = ticketOptions.value.find((option) => option.label === label).pricetypeId;

			newValues.push({ id, label });
		});

		ticketTypesUsed.value = newValues;
	};

	const shouldShowLabel = (label, index) => {
		if (ticketTypesUsed.value.length === 0) return true;

		const used = ticketTypesUsed.value.find((used) => used.label === label);

		if (used) {
			if (used.id == index) return true;
			return false;
		}

		return true;
	};

	const getMaxCount = (index) => {
		// Base max case
		const max = maxTickets.value - quantity.value + quantities.value[index].count;
		// If the ticket types haven't updated yet, this errors out.
		if (ticketTypesUsed.value?.length < index + 1) return max;

		// Grab the label for the current ticketType
		const ticketLabel = ticketTypesUsed.value[index].label;
		let isPublic = true;
		publicTickets.value.forEach((ticket) => {
			if (!ticketLabel.includes(ticket.description)) isPublic = false;
		});

		// If user has a membership and the ticket is not public, limit the ticket count
		if (user.value?.membership?.level && !isPublic) {
			const membership = user.value.membership.level;
			const limit = membershipLimits[membership];
			if (limit < max) return limit;
		} else {
			return max;
		}
	};

	const submit = async () => {
		if (submitting.value) return;

		errors.value = [];

		submitting.value = true;

		if (quantity.value === 0) {
			errors.value.push('Please select at least one ticket');
			submitting.value = false;
			return;
		}

		if (!performanceId.value) {
			errors.value.push('Please select a performance');
			submitting.value = false;
			return;
		}

		if (!productionSeasonId.value) {
			errors.value.push('There was an error adding this to cart. Please try again.');
			submitting.value = false;
			return;
		}

		const data = {
			performanceId: performanceId.value,
			productionSeasonId: productionSeasonId.value,
			'ticketReservationRequests[0][pricetypeId]': 1,
			'ticketReservationRequests[0][quantity]': quantity.value,
			specialRequests: 'ContiguousSeats=1',
		};

		const response = await $fetch('/api/tn/add', {
			method: 'POST',
			credentials: 'include',
			body: JSON.stringify(data),
		}).catch((error) => {
			errors.value.push('There was an error adding this to cart. Please try again.');
			submitting.value = false;
		});

		if (response?.error) {
			errors.value.push(response.error);
			submitting.value = false;
			return;
		}

		// const { $bus } = useNuxtApp();
		// $bus.emit('cart-update');
		if (process.client) await setCart();
		$bus.emit('open-cart-dropdown');
		reset();
		submitting.value = false;
		updateTotal();
		updateTicketTypesUsed();
	};

	const reset = () => {
		ticketTypesArray.value = [0];
		ticketTypeCount.value = 0;
		quantities.value = { 0: { count: 1 } };
		tickets.value = null;
		total.value = 0;
		ticketTypesUsed.value = [];
		errors.value = [];
	};

	const makeLabel = (price) => {
		let label = '$';
		label += price.price;
		label += ' - ';
		label += price.description;
		return label;
	};

	const showErrors = () => {
		console.log('showErrors');
		showError.value = true;
		setTimeout(() => {
			showError.value = false;
		}, 3000);
	};

	// Lifecycle
	onMounted(() => {
		updateTotal();
		updateTicketTypesUsed();
		updateMaxTickets();
	});

	watch(quantity, () => {
		updateTotal();
		updateTicketTypesUsed();
	});

	watch(performanceId, () => {
		updateMaxTickets();

		setTimeout(() => {
			updateTotal();
			updateTicketTypesUsed();
		}, 16);
	});
</script>

<template>
	<div class="templates-tour-pricing">
		<div class="pricing">
			<strong class="h6">Ticket Price</strong>
			<ul>
				<li v-for="price in ticketOptions">{{ price.label }}</li>
			</ul>
		</div>

		<!-- <strong class="h6">{{ tourDetailPage.selectTicketType.label }}</strong> -->
		<template v-if="isTour">
			<MessHtml :html="tourDetailPage.selectTicketType.message" class="message copy fs-14" />

			<div class="accessibility copy">
				<MessHtml v-if="tourDetailPage.accessibilityMessage" :html="tourDetailPage.accessibilityMessage" />
			</div>
		</template>

		<div class="error-wrapper" @click="showErrors" v-if="!performanceId && !productionSeasonId">
			<ProjectButton
				v-if="button"
				:label="button.label"
				title="You must select a departure time before you can purchase tickets"
				version="red-fill"
				class="disabled"
			/>
			<ProjectButton
				v-else
				:label="tourDetailPage.buttonLabel"
				title="You must select a departure time before you can purchase tickets"
				version="red-fill"
				class="disabled"
			/>
			<p class="show-error" v-if="showError">{{ `You must select a ${isTour ? 'departure time' : 'date'} first.` }}</p>
		</div>

		<template v-else>
			<ProjectButton
				v-if="button"
				:label="button.label"
				:title="button.title"
				:link="button.link || `https://cart.architecture.org/${productionSeasonId}/${performanceId}`"
				version="red-fill"
				:disabled="quantity > maxTickets"
				:forceInternalLink="true"
				class="needs-margin"
			/>
			<ProjectButton
				v-else
				:label="tourDetailPage.buttonLabel"
				title="This will take you to a page where you can select the number of tickets you would like to purchase."
				:link="`https://cart.architecture.org/${productionSeasonId}/${performanceId}`"
				:disabled="quantity > maxTickets"
				version="red-fill"
				:forceInternalLink="true"
				class="needs-margin"
			/>
			<div class="error-wrapper" style="margin-top: 1rem" v-if="quantity > maxTickets">
				<p class="show-error">There are no more tickets available for this tour.</p>
			</div>
		</template>
	</div>
</template>

<style lang="scss">
	.templates-tour-pricing {
		margin-top: 2rem;

		.h6 {
			text-transform: uppercase;
			font-weight: 900;

			&.red {
				display: inline-block;
				margin-top: 2rem;
				color: var(--red);
			}
		}

		.needs-margin {
			margin-top: 1rem;
		}

		.message,
		.accessibility {
			margin-top: 0.25rem;
			font-size: 0.875rem;
			max-width: 62ch;
		}

		.message {
			font-size: 14px;
			* {
				font-size: inherit;
			}
		}

		.select-departure {
			margin-top: 1.5rem;
		}

		form {
			margin-top: 1.5rem;
			position: relative;

			.blocker {
				position: absolute;
				--padding: 0rem;
				--half-padding: calc(var(--padding) / 2);
				width: calc(100% + var(--padding));
				height: calc(100% + var(--padding) - 5rem);
				top: calc(-1 * var(--half-padding));
				left: calc(-1 * var(--half-padding));
				background: var(--gray-500);
				background: rgb(157, 159, 162, 0.5);
				z-index: 1;
				border: 1px solid black;

				.loader {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);

					--color-1: var(--red);
					--color-2: var(--white);
					--color-3: var(--black);
				}
			}

			.ticket-type-wraper {
				display: flex;
				align-items: center;
				margin-bottom: 1rem;
				gap: 1rem;

				.remove {
					width: 1rem;
					height: 1rem;
					display: inline-flex;
					justify-content: center;
					align-items: center;
					transition: 250ms ease;

					&:hover {
						opacity: 0.8;
						transform: scale(0.8);
					}

					svg {
						width: 0.75rem;
						height: 0.75rem;
					}
				}

				.select-wrapper {
					position: relative;

					select {
						width: 45ch;
						height: 100%;
						padding: 1rem;
						padding-right: 2.5rem;
						border: 1px solid var(--black);
						color: var(--black);
						font-size: 16px;
						font-weight: 350;
						line-height: 156.1%;
						letter-spacing: 0.24px;
						flex-grow: 1;
						position: relative;
						pointer-events: all;
						cursor: pointer;
					}
					.arrow {
						position: absolute;
						right: 0.75rem;
						width: 1rem;
						height: 1rem;
						top: 50%;
						transform: translateY(-50%);
						user-select: none;
						pointer-events: none;
					}
				}

				.input-wrapper {
					height: 100%;
					border: 1px solid black;
					border-radius: 22px;
					display: flex;
					align-items: center;
					padding: 14px 22px;

					input[type='number'] {
						width: calc(1rem + 4ch);
						text-align: center;
						margin-right: -1rem;

						user-select: none;
						pointer-events: none;

						text-align: center;
						color: var(--black);
						font-family: Avenir, 'Avenir fallback';
						font-size: 14px;
						font-style: normal;
						font-weight: 900;
						line-height: 123.1%;
						letter-spacing: 1.61px;
						text-transform: uppercase;
					}

					button {
						display: flex;
						height: 1rem;
						width: 1rem;
						justify-content: center;
						align-items: center;

						&[disabled] {
							cursor: not-allowed;
							opacity: 0.5;
						}

						svg {
							width: 0.8rem;
							height: 0.8rem;
						}
					}
				}

				+ .ticket-type-wraper {
					.select-wrapper {
						select {
							width: calc(45ch - 1rem - 1rem);
						}
					}
				}
			}

			.add-ticket {
				color: var(--grey-700);
				font-size: 14px;
				font-weight: 500;
				line-height: 25px;
				letter-spacing: 1.12px;
				text-transform: uppercase;
				background: none;
				border: none;
				cursor: pointer;
			}
		}

		.accessibility {
			margin-top: 1rem;
			color: #000;

			/* Body/Body 2 Medium */
			font-family: Avenir;
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: 156.1%; /* 24.976px */
			letter-spacing: 0.24px;
		}

		.submit-button {
			margin-top: 2rem;
			color: var(--white, #fff);
			text-align: center;
			/* Utility/Button Label LG */
			font-family: Avenir;
			font-size: 14px;
			font-style: normal;
			font-weight: 900;
			line-height: 123.1%; /* 17.234px */
			letter-spacing: 1.61px;
			text-transform: uppercase;

			background: var(--red);

			&:hover {
				background: white;
				color: var(--red);
				border: 1px solid var(--red);
			}
		}

		.pricing {
			margin: 2rem 0 1rem;
			li {
				font-size: 20px;
			}
		}

		.error-wrapper {
			margin-top: 2rem;
			.show-error {
				color: var(--red);
				// font-size: 14px;
				font-weight: 900;
				margin-top: 0.5rem;
			}
		}
	}
</style>
